import uri from 'urijs';
import { EndPoint, getURL, Request } from '@model/service';
import { Continent, Country, Region, TripTypes } from '@model/common';
import { ResortLanding, PageTemplates, PageConfig, PageData } from '@model/content';

interface ContentApiRequests {
  getGeography: (path?: string) => Promise<Array<Continent>>;
  getCountry: (path: string) => Promise<Array<Country>>;
  getDestination: (path: string) => Promise<Array<Country> | Array<Region>>;
  getResort: (path: string) => Promise<ResortLanding>;
  getPageConfigMap: ({ template }: PageConfigParams) => Promise<Array<PageData>>;
  getPageConfig: ({ template, path, tripType }: PageConfigParams) => Promise<PageConfig>;
}

interface PageConfigParams {
  template: PageTemplates;
  path?: string;
  tripType?: TripTypes;
}

export class ContentApi implements ContentApiRequests {
  private api: Request = new Request();

  public async getGeography(): Promise<Array<Continent>> {
    return await this.api.get(uri(getURL()).path(EndPoint.CONTENT.concat(EndPoint.GEOGRAPHY_DESTINATIONS)).href());
  }

  public async getCountry(path: string): Promise<Array<Country>> {
    return await this.api.get(
      uri(getURL()).path(EndPoint.CONTENT.concat(EndPoint.GEOGRAPHY_DESTINATIONS)).search({ path }).href()
    );
  }

  public async getDestination(path: string): Promise<Array<Country> | Array<Region>> {
    return await this.api.get(
      uri(getURL()).path(EndPoint.CONTENT.concat(EndPoint.GEOGRAPHY_DESTINATIONS)).search({ path }).href()
    );
  }

  public async getResort(path: string): Promise<ResortLanding> {
    return await this.api
      .get(uri(getURL()).path(EndPoint.CMS.concat(EndPoint.RESORT_LANDING_PAGE)).search({ path }).href())
      .catch(() => {
        return null;
      });
  }

  public async getCarousel(id: number | string) {
    return await this.api.get(
      uri(getURL())
        .path(EndPoint.CONTENT.concat(EndPoint.CAROUSEL).concat(`/${id}`))
        .href()
    );
  }

  public async getPageConfigMap({ template }: PageConfigParams): Promise<Array<PageData>> {
    return await this.api
      .get(
        uri(getURL())
          .path(EndPoint.CONTENT.concat(EndPoint.PAGE_CONFIG_MAP).concat(`/${template}`))
          .href()
      )
      .catch(() => {
        return null;
      });
  }
  public async getPageConfig({ template, path, tripType }: PageConfigParams): Promise<PageConfig> {
    return await this.api
      .get(
        uri(getURL())
          .path(EndPoint.CONTENT.concat(EndPoint.PAGE_CONFIG).concat(`/${template}`))
          .search({ path, tripType })
          .href(),
        undefined,
        {
          suppressErrorLogs: true
        }
      )
      .catch(() => {
        return null;
      });
  }
}

import { omit, omitBy, isNil, pick, flatten, flattenDeep, map, isUndefined } from 'lodash';
import { Page, Keyable } from '@model/common';
import { Name } from '@model/iceberg';
import { ToursStateName, TourHit, TourType } from '@model/search/alogolia';
import { AlgoliaAirportCacheTypes } from '@model/state/algolia-state';
import { tourTypes, TripTypes, TripSearchTypes } from '@model/common/tours/trip-types';
import { TourSearchParams } from '@model/state/search-forms-state';
import {
  TourSearchUrlParams,
  ToursApiParams,
  TourAvailability,
  TourAvailabilityResult,
  TourAvailabilityHotelYear,
  TourSpecialOffersQueryUrlParams,
  TourContent
} from '@model/tours';
import { CMSDestinations } from '@model/contentful/destination';
import { FILTER_DEFAULTS } from '@model/price-calendar';
import { getAdults, parseOccupancy } from '@util/deal-finder';
import { findCmsDestinationByPath } from '@util/cms';
import { getQueryAsString } from '@util/common';
import { TourSummaryState } from '@state/tours';

export const isSingleTour = (tour: TourHit) => {
  return tour.type !== TourType.DESTINATION;
};

export const getToursStateNameByType = (tripType: TripTypes) => {
  switch (tripType) {
    case TripTypes.PRIVATE_TOURS:
      return ToursStateName.PRIVATE_TOURS;
    case TripTypes.RIVER_CRUISES:
      return ToursStateName.RIVER_CRUISES;
    case TripTypes.MULTI_CENTRE:
      return ToursStateName.MULTI_CENTRE;
    case TripTypes.CRUISES:
      return ToursStateName.CRUISES;
    default:
      return ToursStateName.ESCORTED_TOURS;
  }
};

export const getAirportsStateNameByType = (tripType?: TripTypes) =>
  tripType && isTourTripType(tripType) ? AlgoliaAirportCacheTypes.TOURS : AlgoliaAirportCacheTypes.HOLIDAYS;

export interface GetToursSearchParamParams {
  tripType: TripTypes;
  tours?: Array<TourHit>;
  searchParams: TourSearchParams;
}
export const getToursSearchUrlParams = ({
  tripType,
  tours,
  searchParams
}: GetToursSearchParamParams): TourSearchUrlParams => {
  const { month, airports, occupancy } = searchParams;
  const hasOccupancy = !!occupancy && occupancy.length > 0;
  const monthParam = month && Array.isArray(month) ? month.join(',') : month;
  const airportsParam = airports && Array.isArray(airports) ? airports.join(',') : airports;

  const urlParams = {
    tripType,
    destinations: tours?.map(({ path }) => path).join(','),
    airports: airportsParam,
    month: monthParam,
    adults: hasOccupancy ? getAdults(occupancy || []) : undefined
  };
  return omitBy(urlParams, isNil);
};

export const toursSearchMapper = (params: TourSearchUrlParams, cmsDestinations: CMSDestinations): ToursApiParams => {
  const { tripType, adults, airports, month, destinations, specialOffers } = params;
  const destinationArray = destinations && destinations?.split(',');

  const places =
    destinationArray && destinationArray.length > 0
      ? destinationArray.map((searchPath) => {
          const cmsDestination = findCmsDestinationByPath(searchPath, cmsDestinations);
          return {
            path: searchPath,
            display: cmsDestination?.title || searchPath
          };
        })
      : [];

  return {
    searchType: (tripType && TripSearchTypes[tripType]) || TripSearchTypes['private-tours'],
    places,
    from: airports ? airports?.split(',') : [],
    duration: '',
    month: month ? month?.split(',') : [],
    occupancy:
      tripType === TripTypes.SOLO_TOURS
        ? parseOccupancy({ adults: '1' })
        : adults
          ? parseOccupancy({ adults })
          : FILTER_DEFAULTS.occupancy,
    specialOffers: specialOffers,
    flexible: false
  };
};

export const getPaceLabel = (pace: number | string) => {
  switch (Number(pace)) {
    case 1:
      return 'tour__product--pace-easygoing';
    case 2:
      return 'tour__product--pace-intermediate';
    case 3:
      return 'tour__product--pace-challenging';
    default:
      return `${pace}`;
  }
};

export const getPaceDescription = (pace: number) => {
  switch (pace) {
    case 1:
      return 'tour__product--pace-description-easygoing';
    case 2:
      return 'tour__product--pace-description-intermediate';
    case 3:
      return 'tour__product--pace-description-challenging';
    default:
      return '';
  }
};

export const isPrivateTour = (tripType?: TripTypes) => (tripType && tripType === TripTypes.PRIVATE_TOURS) || false;

export const isTourTripType = (tripType?: TripTypes): boolean => !!tripType && tourTypes.includes(tripType);

const SharedToursUrlParams = [
  'searchToken',
  'tripType',
  'destinations',
  'airports',
  'adults',
  'month',
  'productPath',
  'departureDate',
  'specialOffers'
];

export const getToursUrl = (url: string, query: Keyable, extras?: Array<string>) => {
  const validQueryObj = pick(query, SharedToursUrlParams.concat(extras || []));
  const filteredQueryObj = omitBy(validQueryObj, isNil);
  return `${url}?${getQueryAsString(filteredQueryObj)}`;
};

export const getTourSearchUrl = (query: Keyable) => {
  return getToursUrl(Page.TOURS_SEARCH, omit(query, ['productPath', 'departureDate']));
};

export const getTourProductUrl = (query: Keyable) => {
  return getToursUrl(Page.TOURS_PRODUCT, omit(query, ['specialOffers']));
};

export const getTourExtrasUrl = (query: Keyable) => {
  return getToursUrl(Page.TOURS_EXTRAS, query, ['tourReferences']);
};

export const getTourGuestsUrl = (query: Keyable) => {
  return getToursUrl(Page.GUESTS, query);
};

export const getTourPaymentUrl = (query: Keyable) => {
  return getToursUrl(Page.PAYMENT, query);
};

export const getHotelYearsFromTourAvailabilityResult = ({
  results
}: TourAvailabilityResult): Array<TourAvailabilityHotelYear> => {
  return flatten(Object.values(results));
};

export const getDiscountsFromHotelYears = (hotelYears: Array<TourAvailabilityHotelYear>): Array<TourAvailability> => {
  const availability: Array<TourAvailability> = flattenDeep(map(hotelYears, ({ months }) => Object.values(months)));
  return availability.filter(({ price }) => !!price.discount);
};

export const getTourSpecialOffersQuery = ({
  specialOffers
}: ToursApiParams): TourSpecialOffersQueryUrlParams | string => {
  if (isUndefined(specialOffers)) {
    return '';
  }

  const formatSpecialOffers: string = specialOffers ? specialOffers.replace('-', ' ') : '';
  return {
    profile: '1',
    special_offers: formatSpecialOffers
  };
};

export const getTourSummary = (tourContent: TourContent, destination: Name) => {
  const { name, imageUrl, description, price, connectId, whatsIncluded } = tourContent;
  const tourSummary: TourSummaryState = {
    tourName: name,
    connectId: connectId,
    tourImage: imageUrl,
    tourDescription: description,
    tourPrice: price,
    destinationName: destination.display,
    whatsIncluded
  };
  return tourSummary;
};

import React, { FC } from 'react';
import styled from '@emotion/styled';
import { ImageResponse } from '@model/iceberg/image';
import { Theme } from '@theme/base';
import { TestId } from '@components/test-ids';
import { ZIndex } from '@styles/z-index';
import { Image } from '@components/common/image';
import { breakpoints, mq } from '@styles/breakpoints';
import { ImageLoaderProps } from 'next/image';

export interface BaseHeroProps {
  id?: string;
  testId?: string;
  className?: any;
  children?: JSX.Element;
  theme?: Theme;
  image: ImageResponse;
  imageLoader?: (payload: ImageLoaderProps) => string;
  renderTop?: any;
  renderMain?: any;
  renderBottom?: any;
  renderBottomBanner?: any;
  loading?: 'lazy' | 'eager';
}

const Container: any = styled.div({
  width: '100vw',
  maxWidth: '100%',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  position: 'relative',
  display: 'flex',
  justifyContent: 'flex-start',
  flexDirection: 'column',
  alignItems: 'center',
  [mq.small]: {
    height: '100vh'
  }
});

const StyledBackground = styled(Image)({
  position: 'absolute',
  display: 'block',
  minWidth: '100%',
  maxWidth: '100%',
  minHeight: '100%',
  maxHeight: '100%',
  objectFit: 'cover'
});

export const ImageContainer = styled.div({
  paddingBottom: '36.89%',
  position: 'relative',
  width: '100%',
  boxSizing: 'border-box',
  [mq.small]: {
    height: 'auto'
  }
});

const Top = styled.div({
  width: '100%'
});

export const Main: any = styled.div({
  flex: 1,
  marginTop: 0,
  width: '100%',
  zIndex: ZIndex.CONTENT,
  [mq.small]: {
    marginTop: 0
  }
});

export const Bottom = styled.div({
  width: '100%',
  height: 100,
  display: 'flex',
  maxWidth: '1312px'
});

export const BaseHero: FC<BaseHeroProps> = ({
  image,
  testId,
  renderTop,
  renderMain,
  renderBottom,
  renderBottomBanner,
  loading,
  className
}: BaseHeroProps) => {
  const src = image?.imageSet[breakpoints.xLarge];
  if (!src) return null;

  return (
    <Container data-testid={testId || TestId.hero} className={className}>
      <ImageContainer>
        <StyledBackground src={src} fill sizes={'100vw'} quality={80} priority={loading === 'eager'} alt={''} />
      </ImageContainer>
      {renderTop && <Top>{renderTop}</Top>}
      {renderMain && <Main>{renderMain}</Main>}
      {renderBottom && <Bottom>{renderBottom}</Bottom>}
      {renderBottomBanner}
    </Container>
  );
};

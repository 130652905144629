import styled from '@emotion/styled';
import { mq } from './breakpoints';
import { ZIndex } from './z-index';
import { ThemeProps } from '@theme/base';

export enum SectionTitleSizes {
  LARGE = 'LARGE',
  REGULAR = 'REGULAR'
}

interface ComponentContainerProps extends ThemeProps {
  fullWidth: boolean;
  isEdgeToEdge: boolean;
  noPadding: boolean;
  isHidden?: boolean;
}
export const ComponentContainer: any = styled.article(
  ({ theme, fullWidth, isEdgeToEdge, noPadding, isHidden }: ComponentContainerProps) => ({
    padding: !isEdgeToEdge && !noPadding ? `0 ${theme.custom.spacing.large}px` : 0,
    margin: '0 auto',
    ...(isHidden && {
      display: 'none'
    }),
    ...(!fullWidth &&
      !isEdgeToEdge && {
        maxWidth: theme.custom.contentSizes.maxContentWidth
      }),
    ...(fullWidth &&
      !isEdgeToEdge && {
        [mq.large]: {
          padding: `0 3%`
        }
      })
  })
);

export const HeaderContainer: any = styled.nav(({ theme }: ThemeProps) => ({
  position: 'relative',
  width: '100%',
  color: theme.custom.colors.black,
  backgroundColor: theme.custom.colors.group2.dark,
  zIndex: ZIndex.NAVIGATION
}));

interface NavBarWrapperProps extends ThemeProps {
  fullWidth: boolean;
}
export const NavBarWrapper: any = styled.div(({ theme, fullWidth }: NavBarWrapperProps) => ({
  padding: theme.custom.spacing.medium,
  marginBottom: 2,
  [mq.small]: {
    marginBottom: 0,
    boxShadow: 'none'
  },
  [mq.large]: {
    padding: fullWidth ? `${theme.custom.spacing.medium}px 3%` : theme.custom.spacing.medium
  },
  ...(!fullWidth &&
    ({
      maxWidth: theme.custom.contentSizes.maxContentWidth,
      marginLeft: 'auto',
      marginRight: 'auto'
    } as any))
}));

export const SectionContainer = styled.div(({ theme }: ThemeProps) => ({
  backgroundColor: theme.custom.colors.group10.lighter,
  borderBottom: `1px solid ${theme.custom.colors.group10.light}`,
  borderTop: `1px solid ${theme.custom.colors.group10.light}`
}));

interface SectionSeparatorProps extends ThemeProps {
  size?: number;
}
export const SectionSeparator: any = styled.div(({ theme, size }: SectionSeparatorProps) => ({
  height: typeof size === 'undefined' ? theme.custom.spacing.xxLarge : size,
  [mq.medium]: {
    height: size || theme.custom.spacing.xxxLarge
  }
}));

interface CMSSeparatorProps {
  height: string;
}
export const CMSSeparator: any = styled.div(({ height }: CMSSeparatorProps) => ({
  height: `${height}px`
}));

interface SeparatorProps extends ThemeProps {
  value: string;
}
export const Separator: any = styled('div', { shouldForwardProp: () => false })(({ theme, value }: SeparatorProps) => ({
  height: `${value === theme.custom.spacing.xxxLarge.toString() ? Number(value) / 2 : value}px`,
  [mq.small]: {
    height: `${value}px`
  }
}));

interface LineSeparatorProps extends ThemeProps {
  color?: string;
}
export const LineSeparator: any = styled.div(({ theme, color }: LineSeparatorProps) => ({
  borderBottom: `1px solid ${color || theme.custom.colors.group10.lighter}`
}));
